<script src="@/api/index.js"></script>
<template>
	<div class="container">
		<div style="position: absolute;top: 1px;left: 1px;font-size: 10px;color: black;z-index: 9999;">版本号：{{ban}}/{{uid}}/{{level}}/{{nickname}}</div>
			<!-- <div class="flex">
				<div class="btn_style btn_32" @click="handleClick(2)">
					<img style="width: 100%;" src="@/assets/img/isteacher.png" alt="">
				</div>
				<div class="btn_style btn_32" @click="handleClick(1)">
					<img style="width: 100%;" src="@/assets/img/isstudent.png" alt="">
				</div>
			</div>
			<div class="btn_style btn_37" @click="handleClick(3)">
				<img style="width: 100%;" src="@/assets/img/oldre.png" alt="">
			</div> -->
			<div class="btns" @click="startFun('chooseIdentity')">
				<img src="@/assets/image/login.png" mode="widthFix" />
			</div>
			<div class="home_main">
				<div class="btn" @click="toUrl(item,item.path)" v-for="(item,index) in imgList" :key="index">
					<img :src="item.imgUrl" mode="widthFix" />
				</div>
			</div>
			<div class="counts">现已有<span style="font-size: 39px;font-weight: bold;">{{ count }}</span>人次参与答题</div>
		<div class="luck_box" v-if="luckShow" @click="luckShow = false">
			<img src="@/assets/image/luck.png" mode="widthFix" />
		</div>
	</div>
</template>

<script>
	import {
		getNum,
		Login,
		getInfo,
		getFollow
	} from '@/api'
	import {
		Dialog,
	} from 'vant';
	export default {
		name: "index",
		data() {
			return {
				count: 0,
				luckShow: false,
				isRegister: 0,
				nickname: '',
				ban: '0.0.5',
				uid: '',
				level:'',
				imgList:[
					{
						imgUrl:require('@/assets/image/fyb.png'),
						path:'paihang',
						type:1
					},
					{
						imgUrl:require('@/assets/image/scjz.png'),
						path:'awards',
						type:1
					},
					{
						imgUrl:require('@/assets/image/csjl.png'),
						path:'',
						type:2
					}
				]
			}
		},
		watch: {
			count(value) {
				// console.log(value)
				this.count = value
			}
		},
		created() {
			if(localStorage.getItem('ban') == null){
				localStorage.clear();
				localStorage.setItem('ban',this.ban)
			}else{
				if(localStorage.getItem('ban')!=this.ban){
					localStorage.clear();
					localStorage.setItem('ban',this.ban)
				}
			}
			if(localStorage.getItem('token') == null){
				this.getCode()
				// localStorage.setItem('token','36731b44-58aa-4c18-ba7a-98fa1caa4f44')
				// localStorage.setItem('user_id',23)
			}else{
				this.getUser()
			}
		},
		mounted() {
			this.get_Num()
		},
		methods: {
			get_Num() {
				getNum().then(res => {
					console.log(res)
					this.count = res.data.data.count
				})
			},
			startFun(url){
				if(this.isRegister == 0){
					this.$router.push({
						name: url,
					})
					return
				}
				this.$router.push({
					name: 'notice',
					query: {
						type: this.level
					}
				})
			},
			toUrl(item,url) {
				if(item.type == 2){
					this.luckShow = true;
					return
				}
				if(item.type == 1){
					this.$router.push({
						name: url,
					})
					return
				}
				if(item.value == 'state'){		
					if(this.isLogin){
						if(this.userinfo.is_register == 1){
							if(this.userinfo.is_answer == 1){
								this.$refs.uToast.show({ 
									type: 'success',
									message: '您已参与过答题',
									iconUrl: 'https://cdn.uviewui.com/uview/demo/toast/success.png'
								})
								return
							}else{
								uni.navigateTo({
									url:url
								})
							}
						}else{ 
							// console.log(this.userinfo)
							uni.navigateTo({
								url:'/pages/user_info/user_info'
							})
						}
					}else if(!this.isLogin){
						uni.navigateTo({
							url:'/pages/login/login'
						})
					}
				}else if(item.value == 'upload'){
					uni.navigateTo({
						url:url
					})
				}else if(item.value == 'end'){
					uni.showToast({
						title:'待开启',
						icon:'none'
					})
				}
			},
			removeToken(){
				Dialog.confirm({
						title: '提示',
						message: '重新登录，操作后请重新刷新页面',
					})
					.then(() => {
						localStorage.clear();
					})
			},
			getUrlCode() { // 截取url中的code方法
				var url = window.location.href
				var theRequest = new Object();
				if (url.indexOf("?") != -1) {
					var str = url.substr(1);
					var strs = str.split("?");
					var strs1 = strs[1].split("&");
					for (var i = 0; i < strs1.length; i++) {
						theRequest[strs1[i].split("=")[0]] = decodeURIComponent(strs1[i].split("=")[1]);
					}
				}
				return theRequest
			},
			// 获取code
			getCode() {
				let code = this.getUrlCode();
				let origin = 'http://aizhutidati.kc87.com/' //网页授权的回调域名，这里设置的是本地端口
				let urlNow = encodeURIComponent(origin); //处理域名
				let scope = "snsapi_userinfo"; //弹框显示授权
				let appid = "wx130f3baa40e5bb1d";
				// let state = uni.getStorageSync('mobile');
				if (code.code == null || code.code === '') { //未授权qu授权
					let url =`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}#wechat_redirect`;
					window.location.href = url;
				} else {
					this.login(code.code)
				}
			},
			login(code){
				let data = {
					code: code,
				}
				Login(data).then(res => {
					if (res.data.code == 1) {
						this.getUser()
						localStorage.setItem('token',res.data.data.token)
						localStorage.setItem('user_id',res.data.data.user_id)
						this.isRegister = res.data.data.is_register
					}else{
						if(res.data.msg=='code错误'){
							window.location.href = 'http://aizhutidati.kc87.com/'
						}
						this.$toast.fail(res.data.msg)
					}
				})
			},
			getUser(){
				let data = {
					token: localStorage.getItem('token')
				}
				getInfo(data).then(res => {
					if(res.data.code==0){
						this.$toast.fail(res.data.msg)
					}else{
						this.isRegister = res.data.data.is_register
						this.level = res.data.data.level
						this.getFollow()
						if(this.nickname!=null){
							this.nickname = res.data.data.nickname
							this.uid = res.data.data.id
						}	
					}
					
				})
			},
			getFollow(){
				let data = {
					token: localStorage.getItem('token')
				}
				getFollow(data).then(res => {
					if(res.data.data.folow_status==0){
						Dialog.confirm({
							title: '提示',
							message: '未关注公众号请前往关注',
							showCancelButton: false,
						})
						.then(() => {
							// window.location.href='https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkyOTUyMzEzMw==&scene=110#wechat_redirect'
							window.open('https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkyOTUyMzEzMw==&scene=110#wechat_redirect','_blank')
						})
					}
				})
			},
			simulationClick(id) {
				this.$router.push({
					name: "dati",
					query: {
						id: id
					}
				})
			},
			//生成奖状
			awardsClick() {
				this.$router.push('/awards/userInfo')
			},
			//top风云榜
			topClick(url) {
				console.log(1111)
				this.$router.push(url)
			}
		}
	}
</script>
<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		background: url(../../assets/image/bg.png) no-repeat center;
		background-size: 100% 100%;
		position: relative;

		.titleimg {
			text-align: center;
			position: relative;
			z-index: 2;

			img {
				width: 75%;
				margin-top: 80px;
			}
		}

		.centent-background {
			width: 100%;
			text-align: center;
			position: absolute;
			top: 50px;

			.yunduo {
				width: 95%;
				height: 100%;
			}

			.a {
				position: relative;
				z-index: 1;
			}

			.c {
				// height: 10rem;
				margin-top: -250px;
			}
		}

		.img_text {
			position: absolute;
			width: 100%;
			top: 75px;

			img {
				width: 100%;
				display: block;
				object-fit: cover;
			}
		}
		.home_main {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top:76%;
			width: 90%;
			display: flex;
			justify-content: center;
			align-items: center;
			.btn {
				width:200px;
				height: 84px;
				box-sizing: border-box;
				margin: 0 10px;
				img {
					width: 100%;
					height: 100% !important;
					object-fit: cover;
				}
			}
		}
		.btns{
			width: 490px;
			height: 130px;
			position: absolute;
			left: 50%;
			top: 67%;
			transform: translateX(-50%);
			img {
				width: 100%;
				height: 100% !important;
				object-fit: cover;
			}
		}

		.flex {
			width: 80%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;

			&::before {
				content: '';
				display: block;
			}

			&:after {
				content: '';
				display: block;
			}
		}


		.tops {
			margin-top: -110px;
			position: relative;
			z-index: 999;
		}

		.btn_style {
			width: 280px;
			height: 90px;
			line-height: 90px;
			border-radius: 20px;
			color: #fff;
			font-weight: bolder;
			font-family: "微软雅黑";
			letter-spacing: 6px;
			text-indent: 6px;
		}

		.btn_37 {
			position: relative;
			width: 350px;
			font-size: 65px;
			margin-bottom: 15px;
			z-index: 3;
		}

		.btn_32 {
			font-size: 45px;
		}

		.form_box {
			padding: 48px 24px 48px 25px;
			width: 100%;
			box-sizing: border-box;
		}

		.bottom {

			.title_p {
				position: absolute;
				bottom: 150px;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
				width: 80%;
				.imgs {
					// width: 100%;
					padding: 0 15px;

					img {
						width: 100%;
					}
				}
			}
		}

		.van-overlay {
			z-index: 3 !important;
		}

		.popup_box {
			// background: #5689ff;
			padding: 15px;
			width: 90%;
			position: relative;
			top: 50%;
			left: 50%;
			border-radius: 10px;
			transform: translate(-50%, -50%);

			.bj_img {
				position: absolute;
				top: -80px;
				width: 470px;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.popup_box_border {
				// background: url("../../assets/img/jli.png") no-repeat center 0;
				background-size: cover;
				height: 100%;
				width: 100%;
				border: 2PX dashed #9fbefc;
				font-size: 35px;
				color: #fff;
				box-sizing: border-box;
				padding: 60px 30px;

				&_title {
					font-family: 'adobe 黑体 std';
					font-weight: 400;

					img {
						width: 38px;
						height: 38px;
						margin-right: 20px;
					}
				}

				&_text {
					font-size: 28px;
					// font-family: 'adobe 黑体 std';
					text-align: justify;
				}

				div {
					margin-bottom: 20px;
				}
			}
		}
	}
	.luck_box{
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
		img {
			width: 90%;
		}
	}
	.counts {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 36px;
		color: black;
		font-weight: 100;
	}
</style>
